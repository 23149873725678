<template>
  <div id="settings-wrapper">
    <!-- <v-btn id="settings" color="grey lighten-1" text>
      <v-icon> mdi-cog </v-icon>
    </v-btn> -->

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab icon @click="rtl = !rtl" v-bind="attrs" v-on="on">
          <v-icon large color="light-blue darken-1">mdi-translate</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("setting.lang") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab icon v-bind="attrs" v-on="on" @click="dark = !dark">
          <v-icon large color="amber">mdi-theme-light-dark</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("setting.darkMode") }}</span>
    </v-tooltip>
    <!-- 
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#settings"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="top right"
      transition="scale-transition"
    >
      <v-card class="text-center mb-0" width="300">
        <v-card-text>
          <strong class="mb-3 d-inline-block">{{
            $t("setting.primaryColor")
          }}</strong>

          <v-item-group v-model="color">
            <v-item v-for="color in colors" :key="color" :value="color">
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="color"
                  class="v-settings__item"
                  size="25"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 secondary" />

          <v-row align="center" no-gutters>
            <v-col cols="auto"> {{ $t("setting.lang") }} </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="rtl"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>
          <v-divider class="my-4 secondary" />

          <v-row align="center" no-gutters>
            <v-col cols="auto"> {{ $t("setting.darkMode") }} </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="dark"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

        
        </v-card-text>
      </v-card>
    </v-menu> -->
  </div>
</template>

<script>
// Mixins
import Proxyable from "vuetify/lib/mixins/proxyable";
import { mapMutations, mapState } from "vuex";

export default {
  name: "DashboardCoreSettings",

  mixins: [Proxyable],

  data: () => ({
    color: "#E91E63",
    colors: [
      "#007cd6",
      "#9C27b0",
      "#00CAE3",
      "#4CAF50",
      "#ff9800",
      "#E91E63",
      "#FF5252",
    ],
    image: "",
    //"https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg"
    images: [
      // "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
      // "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-2.jpg",
      // "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-3.jpg",
      // "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-4.jpg",
    ],
    menu: false,
    saveImage: "",
    showImg: true,
    rtl: localStorage.rtl === "true",
    dark: localStorage.dark === "true",
  }),

  computed: {
    ...mapState(["barImage"]),
  },

  created() {
    this.$vuetify.rtl = this.rtl;
    this.$vuetify.theme.dark = this.dark;

    var lang = this.rtl ? "ar" : "en";
    localStorage.lang = lang;
    this.$i18n.locale = lang;
  },

  watch: {
    color(val) {
      this.$vuetify.theme.themes[this.isDark ? "dark" : "light"].primary = val;
    },
    showImg(val) {
      if (!val) {
        this.saveImage = this.barImage;
        this.setBarImage("");
      } else if (this.saveImage) {
        this.setBarImage(this.saveImage);
        this.saveImage = "";
      } else {
        this.setBarImage(val);
      }
    },
    rtl(val) {
      localStorage.rtl = val;
      this.$vuetify.rtl = this.rtl;

      var lang = val ? "ar" : "en";
      localStorage.lang = lang;
      this.$i18n.locale = lang;
    },
    dark(val) {
      // console.log(val);
      localStorage.dark = val;
      this.$vuetify.theme.dark = this.dark;
    },
    image(val) {
      this.setBarImage(val);
    },
  },

  methods: {
    ...mapMutations({
      setBarImage: "SET_BAR_IMAGE",
    }),
  },
};
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

    // &__item
    //   border-width: 3px
    //   border-style: solid
    //   border-color: transparent !important

    //   &--active
    //     border-color: #00cae3 !important
</style>
