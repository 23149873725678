import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    component: () => import("@/views/Index"),
    children: [
      {
        name: "Login",
        path: "",
        component: () => import("@/views/Account/Login"),
      },
    ],
  },
  {
    path: "/",
    name: "Main",
    component: Home,
    beforeEnter: (to, from, next) => {
      // window.document.title = to.name ? i18n.t(to.name) : "UIMS";
      if (
        localStorage.getItem("token") &&
        localStorage.getItem("token") !== "null" &&
        localStorage.getItem("token") !== null &&
        localStorage.getItem("token") !== undefined &&
        localStorage.getItem("token") !== "undefined" &&
        localStorage.getItem("token").substr(0, 2) === "ey"
      ) {
        next();
      } else {
        next({
          name: "Login",
        });
      }
    },
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/Dashboard"),
      },
      {
        path: "/users",
        name: "users.users",
        component: () => import("../views/Account/Users.vue"),
      },

      {
        path: "/branch",
        name: "branches.branches",
        component: () => import("../views/Account/Branch.vue"),
      },
      {
        path: "/doctors",
        name: "doctors.doctors",
        component: () => import("../views/Account/Doctor.vue"),
      },
      {
        path: "/services",
        name: "services.services",
        component: () => import("../views/Services/Index.vue"),
      },
      {
        path: "/servicecharge",
        name: "services.serviceCharges",
        component: () => import("../views/Services/ServiceCharge.vue"),
      },
      {
        path: "/patients",
        name: "patients.patientList",
        component: () => import("../views/Patients/Index.vue"),
      },
      {
        path: "/patientregistration/:guid",
        name: "patients.patientRegistration",
        component: () => import("../views/Patients/PatientRegistration.vue"),
      },
      {
        path: "/patientregistration",
        name: "patients.patientRegistration",
        component: () => import("../views/Patients/PatientRegistration.vue"),
      },
      {
        path: "/appointments",
        name: "appointments.appointments",
        component: () => import("../views/Patients/Appointment.vue"),
      },
      {
        path: "/patientschedule",
        name: "patients.patientSchedule",
        component: () => import("../views/Patients/PatientsSchedule.vue"),
      },
      {
        path: "/patienttreatment/:guid",
        name: "patients.patientTreatment",
        component: () => import("../views/Patients/PatientTreatment.vue"),
      },
      {
        path: "/groups",
        name: "groups.groups",
        component: () => import("../views/Account/Groups.vue"),
      },
      {
        path: "/prescriptionItems",
        name: "prescriptions.prescriptionsItems",
        component: () => import("../views/Prescription/PrescriptionItems.vue"),
      },
      {
        path: "/prescriptionGroup",
        name: "prescriptions.prescriptionsList",
        component: () => import("../views/Prescription/PrescriptionGroup.vue"),
      },

      {
        path: "/prescriptionGroupItems/:guid",
        name: "prescriptions.editPrescriptionGroup",
        component: () =>
          import("../views/Prescription/PrescriptionGroupItems.vue"),
      },

      {
        path: "/prescriptionGroupItems",
        name: "prescriptions.addPrescriptionGroup",
        component: () =>
          import("../views/Prescription/PrescriptionGroupItems.vue"),
      },

      {
        path: "/addPrescription/:guid",
        name: "Add Prescription",
        component: () => import("../views/Prescription/AddPrescription.vue"),
      },
      {
        path: "/billDetails/:guid",
        name: "bills.billDetails",
        component: () => import("../views/Bills/BillDetails.vue"),
      },
      {
        path: "/bills",
        name: "bills.bills",
        component: () => import("../views/Bills/Index.vue"),
      },
      {
        path: "/vouchers",
        name: "vouchers.vouchers",
        component: () => import("../views/Vouchers/Index.vue"),
      },

      {
        path: "/accountTree",
        name: "accountTree.accountTree",
        component: () => import("../views/Account/AccountTree.vue"),
      },
      {
        path: "/countries",
        name: "country.countries",
        component: () => import("../views/Country/Index.vue"),
      },
      {
        path: "/countryCities",
        name: "country.countryAdd",
        component: () => import("../views/Country/ContryAndCity.vue"),
      },
      {
        path: "/countryCities/:guid",
        name: "country.countryEdit",
        component: () => import("../views/Country/ContryAndCity.vue"),
      },
      {
        path: "/changePassword",
        name: "users.changePassword",
        component: () => import("../views/Account/ChangePassword.vue"),
      },
      {
        path: "/settings",
        name: "settings.settings",
        component: () => import("../views/Account/Settings.vue"),
      },
      {
        path: "/billReport",
        name: "reports.billReport",
        component: () => import("../views/Reports/BillReport.vue"),
      },
      {
        path: "/notauthorize",
        name: "notauthorize",
        component: () => import("../views/NotAuthorized.vue"),
      },
    ],
  },

  {
    path: "*",
    component: () => import("@/views/Home"),
    children: [
      {
        name: "404 Error",
        path: "",
        component: () => import("@/views/Error"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// const PAGE_TITLE = {
//   Dashboard: "Your Dashboard",
//   About: "About Us Page",
//   Branch: "Branches Page",
//   Users: "Users Page",
//   Doctor: "Doctors Page",
//   Services: "Service Page",
//   "Service Charge": "Service Charge",
//   "Patient Registration": "Patient Registration",
//   "Patient Registration Edit": "Patient Registration Edit",
//   Patients: "Patients",
//   Appointments: "Appointments",
//   Login: "Log in",
// };

router.afterEach((toRoute) => {
  window.document.title = i18n.t(toRoute.name);
});

export default router;
