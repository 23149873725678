<template>
  <v-navigation-drawer
    v-model="drawer"
    id="core-navigation-drawer"
    :right="$vuetify.rtl"
    app
    width="270"
    v-bind="$attrs"
  >
    <!-- <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template> -->
    <!-- v-model="drawer" 
        :expand-on-hover="drawer"-->

    <!-- <v-divider class="mb-1" /> -->

    <v-list dense nav class="ma-0 pa-0">
      <v-list-item class="ma-0 pa-0">
        <!-- <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img
            src="https://demos.creative-tim.com/vuetify-material-dashboard/favicon.ico"
            max-height="30"
          />
        </v-list-item-avatar> -->
        <v-btn
          style="padding: 31px !important"
          class="ma-0"
          min-width="100%"
          min-height="100%"
          color="info"
          tile
          depressed
          to="/"
        >
          <!-- <v-icon>mdi-view-dashboard</v-icon> -->

          <span dir="auto" class="pt-1 mx-1">
            ClinicMS <v-icon large> mdi-dot-net </v-icon></span
          >
        </v-btn>

        <!-- <v-list-item-content>
          <v-list-item-title class="display-1" v-text="profile.title" />
        </v-list-item-content> -->
      </v-list-item>
    </v-list>

    <!-- <v-divider class="mb-2" /> -->

    <v-list nav>
      <template v-for="(item, i) in computedItems">
        <v-list-group
          exact
          :key="i"
          :prepend-icon="item.icon"
          v-if="item.children"
          v-model="item.active"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> {{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            active-class="primary"
            color="white"
            v-for="(subItem, ind) in item.children"
            :key="ind"
            :to="subItem.to"
          >
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-title class="mx-n2">
              <v-icon>mdi-circle-small</v-icon> {{ $t(subItem.title) }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else
          :to="item.to"
          :key="i"
          active-class="primary"
          color="white"
        >
          <v-list-item-icon>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
    <!-- 
    <v-list expand nav>
      
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

     
      <div />
    </v-list> -->
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    myItems: [],
    drawer1: true,
    items: [
      {
        icon: "mdi-view-dashboard-outline",
        title: "Dashboard",
        group: "dashboard",
        children: [
          {
            title: "groups.groups",
            to: "/groups",
            role: "0",
          },
          {
            icon: "mdi-account",
            title: "users.users",
            to: "/users",
            role: "0",
          },
          {
            title: "branches.branches",
            icon: "mdi-clipboard-outline",
            to: "/branch",
            role: "1",
          },

          {
            title: "doctors.doctors",
            icon: "mdi-format-font",
            to: "/doctors",
            role: "6",
          },
          {
            title: "country.countries",
            icon: "mdi-clipboard-outline",
            to: "/countries",
            role: "10",
          },
          {
            title: "accountTree.accountTree",
            icon: "mdi-clipboard-outline",
            to: "/accountTree",
            role: "14",
          },
          {
            title: "settings.settings",
            icon: "mdi-cog",
            to: "/settings",
            role: "63",
          },
        ],
      },

      {
        title: "services.services",
        icon: "mdi-hand-heart-outline",
        group: "services",
        role: "49",
        children: [
          {
            title: "services.servicesList",
            icon: "mdi-chart-bubble",
            to: "/services",
            role: "49",
          },
          {
            title: "services.serviceCharges",
            icon: "mdi-map-marker",
            to: "/serviceCharge",
            role: "53",
          },
        ],
      },

      {
        title: "patients.patients",
        icon: "mdi-account-group-outline",
        group: "patients",
        role: "28",

        children: [
          {
            title: "patients.patientList",
            icon: "mdi-chart-bubble",
            to: "/patients",
          },
          {
            title: "patients.patientRegistration",
            icon: "mdi-map-marker",
            to: "/patientregistration",
            role: "29",
          },
        ],
      },
      {
        title: "appointments.appointments",
        icon: "mdi-clipboard-list-outline",
        to: "/appointments",
        role: "18",
      },
      {
        title: "patients.patientSchedule",
        //icon: "mdi-clipboard-list-outline",
        //icon: "mdi-account-box-multiple-outline",
        icon: "mdi-calendar-account-outline",
        to: "/patientschedule",
        role: "19",
      },
      {
        title: "bills.bills",
        icon: "mdi-file-document-edit-outline",
        to: "/bills",
        role: "24",
      },
      {
        title: "vouchers.vouchers",
        icon: "mdi-cash-multiple",
        to: "/vouchers",
        role: "57",
      },
      {
        title: "prescriptions.prescriptions",
        icon: "mdi-clipboard-text-multiple-outline",
        role: "36",
        group: "prescription",
        children: [
          {
            title: "prescriptions.prescriptionsList",
            icon: "mdi-chart-bubble",
            to: "/prescriptionGroup",
          },
          {
            title: "prescriptions.prescriptionsItems",
            icon: "mdi-map-marker",
            to: "/prescriptionItems",
            role: "40",
          },
          // {
          //   title: "prescriptions.addPrescriptionGroup",
          //   icon: "mdi-clipboard-list-outline",
          //   to: "/prescriptionGroupItems",
          //   role: "37",
          // },
        ],
      },
      {
        title: "reports.reports",
        icon: "mdi-clipboard-text-multiple-outline",
        role: "69",
        group: "reports",
        children: [
          {
            title: "reports.billReport",
            icon: "mdi-chart-bubble",
            to: "/billReport",
            role: "70",
          },
        ],
      },
    ],
  }),
  watch: {
    drawer1() {
      //console.log(val);

      this.drawer1 = true;
    },
  },
  created() {
    var childs = this.items.filter((item) => {
      return (
        item.children &&
        item.children.some((it) => {
          return it.to == this.$route.path;
        })
      );
    });
    if (childs && childs.length > 0) {
      childs[0].active = true;
    }
  },
  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      var indexes = [];
      var items = JSON.parse(JSON.stringify(this.items));

      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item.role) {
          if (!this.isInRole(item.role)) {
            items[i] = null;
          }
        }
        var childRoles = [];
        var child = item.children;
        if (child) {
          for (var j = 0; j < child.length; j++) {
            var subItem = child[j];

            if (subItem.role) {
              if (!this.isInRole(subItem.role)) {
                child[j] = null;
              } else {
                childRoles.push(subItem.role);
              }
            }
          }
          item.children = child.filter((f) => {
            return f != null && f != "null";
          });
        }

        if (childRoles.length == 0 && !item.role) {
          indexes.push(i);
        }
      }

      for (var m = 0; m < indexes.length; m++) {
        items.splice(indexes[m], 1);
      }

      return items.filter((f) => {
        return f != null && f != "null";
      });
    },
    profile() {
      return {
        avatar: true,
        //title: this.$t("avatar"),
      };
    },
  },

  methods: {
    mapItem(item) {
      // var ch = item.children;
      // if (ch) {
      //   ch = item.children.filter(this.mapItem);
      //   console.log(ch);
      // }
      // if (item.role) {
      //   if (this.isInRole(item.role)) {
      //     myItems.push({
      //       title: item.title,
      //       icon: item.icon,
      //       group: item.group,
      //       children: ch,
      //     });
      //   }
      // } else {
      return {
        title: item.title,
        icon: item.icon,
        group: item.group,
        //children: ch,
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
